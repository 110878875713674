
import { $t } from './services/i18n.js';

export const AppMenus = {
    
	navbarTopRight: [
  {
    "to": "/customer",
    "label": $t('customer'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/table",
    "label": $t('table'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/booking",
    "label": $t('booking'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	navbarTopLeft: [
  {
    "to": "/customer",
    "label": $t('customer'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/table",
    "label": $t('table'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/booking",
    "label": $t('booking'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": $t('home'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/store branch",
    "label": $t('storeBranch'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/customer",
    "label": $t('customer'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/table",
    "label": $t('table'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/booking",
    "label": $t('booking'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/branchstaff",
    "label": $t('branchStaff'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/category",
    "label": $t('category'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/product",
    "label": $t('product'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/store",
    "label": $t('store'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/storebranch",
    "label": $t('storeBranch'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/transaction",
    "label": $t('transaction'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/transactionitems",
    "label": $t('transactionItems'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/user",
    "label": $t('user'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles",
    "label": $t('roles'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions",
    "label": $t('permissions'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    locales: {
  "fr": "French",
  "en-US": "English"
}
}