
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//booking routes
			{
				path: '/booking/:fieldName?/:fieldValue?',
				name: 'bookinglist',
				component: () => import('./pages/booking/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/booking/view/:id', 
				name: 'bookingview', 
				component: () => import('./pages/booking/view.vue'), 
				props: true
			},
		
			{ 
				path: '/booking/add', 
				name: 'bookingadd', 
				component: () => import('./pages/booking/add.vue'), 
				props: true
			},
	
			{ 
				path: '/booking/edit/:id', 
				name: 'bookingedit', 
				component: () => import('./pages/booking/edit.vue'), 
				props: true
			},
		

//branchstaff routes
			{
				path: '/branchstaff/:fieldName?/:fieldValue?',
				name: 'branchstafflist',
				component: () => import('./pages/branchstaff/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/branchstaff/view/:id', 
				name: 'branchstaffview', 
				component: () => import('./pages/branchstaff/view.vue'), 
				props: true
			},
		
			{ 
				path: '/branchstaff/add', 
				name: 'branchstaffadd', 
				component: () => import('./pages/branchstaff/add.vue'), 
				props: true
			},
	
			{ 
				path: '/branchstaff/edit/:id', 
				name: 'branchstaffedit', 
				component: () => import('./pages/branchstaff/edit.vue'), 
				props: true
			},
		

//category routes
			{
				path: '/category/:fieldName?/:fieldValue?',
				name: 'categorylist',
				component: () => import('./pages/category/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/category/view/:id', 
				name: 'categoryview', 
				component: () => import('./pages/category/view.vue'), 
				props: true
			},
		
			{ 
				path: '/category/add', 
				name: 'categoryadd', 
				component: () => import('./pages/category/add.vue'), 
				props: true
			},
	
			{ 
				path: '/category/edit/:id', 
				name: 'categoryedit', 
				component: () => import('./pages/category/edit.vue'), 
				props: true
			},
		

//customer routes
			{
				path: '/customer/:fieldName?/:fieldValue?',
				name: 'customerlist',
				component: () => import('./pages/customer/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/customer/view/:id', 
				name: 'customerview', 
				component: () => import('./pages/customer/view.vue'), 
				props: true
			},
		
			{ 
				path: '/customer/add', 
				name: 'customeradd', 
				component: () => import('./pages/customer/add.vue'), 
				props: true
			},
	
			{ 
				path: '/customer/edit/:id', 
				name: 'customeredit', 
				component: () => import('./pages/customer/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//product routes
			{
				path: '/product/:fieldName?/:fieldValue?',
				name: 'productlist',
				component: () => import('./pages/product/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/product/view/:id', 
				name: 'productview', 
				component: () => import('./pages/product/view.vue'), 
				props: true
			},
		
			{ 
				path: '/product/add', 
				name: 'productadd', 
				component: () => import('./pages/product/add.vue'), 
				props: true
			},
	
			{ 
				path: '/product/edit/:id', 
				name: 'productedit', 
				component: () => import('./pages/product/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//store routes
			{
				path: '/store/:fieldName?/:fieldValue?',
				name: 'storelist',
				component: () => import('./pages/store/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/store/view/:id', 
				name: 'storeview', 
				component: () => import('./pages/store/view.vue'), 
				props: true
			},
		
			{ 
				path: '/store/add', 
				name: 'storeadd', 
				component: () => import('./pages/store/add.vue'), 
				props: true
			},
	
			{ 
				path: '/store/edit/:id', 
				name: 'storeedit', 
				component: () => import('./pages/store/edit.vue'), 
				props: true
			},
		

//storebranch routes
			{
				path: '/storebranch/:fieldName?/:fieldValue?',
				name: 'storebranchlist',
				component: () => import('./pages/storebranch/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/storebranch/view/:id', 
				name: 'storebranchview', 
				component: () => import('./pages/storebranch/view.vue'), 
				props: true
			},
		
			{ 
				path: '/storebranch/add', 
				name: 'storebranchadd', 
				component: () => import('./pages/storebranch/add.vue'), 
				props: true
			},
	
			{ 
				path: '/storebranch/edit/:id', 
				name: 'storebranchedit', 
				component: () => import('./pages/storebranch/edit.vue'), 
				props: true
			},
		

//table routes
			{
				path: '/table/:fieldName?/:fieldValue?',
				name: 'tablelist',
				component: () => import('./pages/table/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/table/view/:id', 
				name: 'tableview', 
				component: () => import('./pages/table/view.vue'), 
				props: true
			},
		
			{ 
				path: '/table/add', 
				name: 'tableadd', 
				component: () => import('./pages/table/add.vue'), 
				props: true
			},
	
			{ 
				path: '/table/edit/:id', 
				name: 'tableedit', 
				component: () => import('./pages/table/edit.vue'), 
				props: true
			},
		

//transaction routes
			{
				path: '/transaction/:fieldName?/:fieldValue?',
				name: 'transactionlist',
				component: () => import('./pages/transaction/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/transaction/view/:id', 
				name: 'transactionview', 
				component: () => import('./pages/transaction/view.vue'), 
				props: true
			},
		
			{ 
				path: '/transaction/add', 
				name: 'transactionadd', 
				component: () => import('./pages/transaction/add.vue'), 
				props: true
			},
	
			{ 
				path: '/transaction/edit/:id', 
				name: 'transactionedit', 
				component: () => import('./pages/transaction/edit.vue'), 
				props: true
			},
		

//transactionitems routes
			{
				path: '/transactionitems/:fieldName?/:fieldValue?',
				name: 'transactionitemslist',
				component: () => import('./pages/transactionitems/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/transactionitems/view/:id', 
				name: 'transactionitemsview', 
				component: () => import('./pages/transactionitems/view.vue'), 
				props: true
			},
		
			{ 
				path: '/transactionitems/add', 
				name: 'transactionitemsadd', 
				component: () => import('./pages/transactionitems/add.vue'), 
				props: true
			},
	
			{ 
				path: '/transactionitems/edit/:id', 
				name: 'transactionitemsedit', 
				component: () => import('./pages/transactionitems/edit.vue'), 
				props: true
			},
		

//user routes
			{
				path: '/user/:fieldName?/:fieldValue?',
				name: 'userlist',
				component: () => import('./pages/user/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/user/view/:id', 
				name: 'userview', 
				component: () => import('./pages/user/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'useruserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'useraccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'useraccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/user/add', 
				name: 'useradd', 
				component: () => import('./pages/user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/user/edit/:id', 
				name: 'useredit', 
				component: () => import('./pages/user/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
